<template>
<div class="content-container checkout-box radius-anchor max-w-xl mx-auto ccss--user-profile">
      <h2 class="text-center text-3xl font-extrabold">
         {{ $t('myprofile.title') }}
      </h2>
   <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <div class="pt-8">
          <router-link :to="{ name: 'user.myaccount' }">
              <a class="link-arrow-left">{{ $t('myaccount') }}</a>
          </router-link>
        <div class="flex mb-5 p-3 rounded-sm text-sm" :class="alert.class" v-show="alert.show">
            <div class="flex-grow">{{ alert.message }}</div>
        </div>
         <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }"> <!--setFieldValue setValues -->

                <div class="mb-3">
                    <label for="name" class="block text-xs" :class="{'text-red-500': errors.name}">{{ $t('fields.name') }} *</label>
                    <div class="mt-1">
                        <Field type="text" v-model="form.name" name="name" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.name}" />
                    </div>
                </div>

            <div class="mb-3">
                <label for="email" class="block text-xs" :class="{'text-red-500': errors.email}">{{ $t('fields.email') }} *</label>
                <div class="mt-1">
                    <Field type="text" v-model="form.email" name="email" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.email}" />
                </div>
            </div>

            <div class="mb-3">
                <label for="phone_number" class="block text-xs" :class="{'text-red-500': errors.phone_number}">{{ $t('fields.phonenumber') }}</label>
                <div class="mt-1">
                    <Field type="text" v-model="form.phone_number" name="phone_number" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.phone_number}" />
                </div>
            </div>

            <div class="sm:grid sm:grid-cols-2 gap-4 ">
                <div class="sm:col-span-1 mb-3">
                    <label for="zipcode" class="block text-xs" :class="{'text-red-500': errors.zipcode}">{{ $t('fields.zipcode') }}</label>
                    <div class="mt-1">
                        <Field @change="getAddress()" v-model="form.zipcode" type="text" name="zipcode" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.zipcode}" />
                    </div>
                </div>
                <div class="sm:col-span-1 mb-3">
                    <label for="housenumber" class="block text-xs" :class="{'text-red-500': errors.housenumber}">{{ $t('fields.housenumber_addition') }}</label>
                    <div class="mt-1">
                        <Field @change="getAddress()" v-model="form.housenumber" type="text" name="housenumber" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.housenumber}" />
                    </div>
                </div>
            </div>

            <!-- streetname -->
            <div class="mb-3">
                <label for="streetname" class="block text-xs" :class="{'text-red-500': errors.streetname}">{{ $t('fields.streetname') }}</label>
                <div class="mt-1">
                    <Field type="text" name="streetname" v-model="form.streetname" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.streetname}" />
                </div>
            </div>

            <!-- city -->
            <div>
                <label for="city" class="block text-xs" :class="{'text-red-500': errors.city}">{{ $t('fields.city') }}</label>
                <div class="mt-1">
                    <Field type="text" name="city" v-model="form.city" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.city}" />
                </div>
            </div>

            <div class="sm:flex text-default">
                <div class="w-full mt-5 relative">
                    <button type="submit" class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                        {{ $t('button.save') }}
                        <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                    </button>
                </div>
            </div>
         </form>

         <modal-user-profile-delete />
      </div>
   </div>
</div>
</template>

<script>
import { Field, Form } from 'vee-validate';
import * as _api from '../lib/api';
import * as _address from '../lib/address';
import * as _notification from '../lib/notification';
import * as yup from "yup";

// modals
import ModalUserProfileDelete from '../components/ModalUserProfileDelete'

export default {
    name: 'UserProfile',
    components: {
        Field,
        Form,
        ModalUserProfileDelete,
    },
    data () {
        const schema = yup.object().shape({
            name: yup.string().required().max(255),
            email: yup.string().required().email().max(255),
            // phone_number: yup.string().max(255),
            // zipcode: yup.string().max(255),
            // housenumber: yup.string().max(255),
            // streetname: yup.string().max(255),
            // city: yup.string().max(255),
        });

        return {
            schema,
            saving: false,
            alert: {
                show: false,
                message: '',
                class: '',
            },
            form: {
                firstname: '',
                name: '',
                email: '',
                phone_number: '',
                zipcode: '',
                password: '',
                password_confirmation: '',
                housenumber: '',
                streetname: '',
                city: '',
            },
        }
    },
    mounted() {
        this.getCustomer()
    },

    methods: {
        async getCustomer() {
            //! needs testing
            const customer = await _api.get('customer', {}, false);

            if(!customer) {
                return;
            }

            this.form.firstname = customer.attributes.firstname
            this.form.name = customer.attributes.name
            this.form.email = customer.attributes.email
            this.form.phone_number = customer.attributes.phone_number
            this.form.zipcode = customer.attributes.zipcode
            this.form.housenumber = customer.attributes.housenumber
            this.form.streetname = customer.attributes.streetname
            this.form.city = customer.attributes.city
        },

        async getAddress() {
            if (this.form.zipcode && this.form.housenumber) {
                const address = await _address.retrieve(this.form.zipcode, this.form.housenumber);

                this.form.streetname = address.streetname;
                this.form.city = address.city;
            }
        },

        async onSubmit (data) {

            this.saving = true

            const response = await _api.post('customer', data, {}, false);
                if (response.result === 'success') {
                    this.$router.push({name: 'user.myaccount'});
                    _notification.set('notification.auth.userprofile-updated', 'success');
                } else {
                    _notification.set('not saved', 'error');
                    window.scrollTo(0, 0);
                }

            this.saving = false

        }
    }
}
</script>
