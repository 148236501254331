<template>
    <div class="mt-10 underline flex justify-center">
        <a class="cursor-pointer text-red-500" @click="open()">{{ $t('modal.deleteprofile.button.open') }}</a>
    </div>


    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed w-full inset-0 overflow-y-auto ccss--modal-login fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">

                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full radius-anchor"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div class="sm:flex sm:items-start">
                            <div class="text-center sm:text-left">
                                <h3 class="mb-5 text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                    {{ $t('modal.deleteprofile.title') }}
                                </h3>
                                <div class="">
                                    <p>{{ $t('modal.deleteprofile.description') }}</p>
                                    <p class="italic mt-2">{{ $t('modal.deleteprofile.description2') }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="px-4 py-3 grid grid-cols-2 gap-2">
                        <button type="button" @click="submit()"
                                class="btn btn-danger"
                        >
                            {{ $t('modal.deleteprofile.button.delete') }}
                        </button>
                        <button type="button" @click="close()"
                                class="btn btn-secondary"
                        >
                            {{ $t('button.close') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import * as _api from '../lib/api';
import * as _auth from '../lib/auth';
import Blur from "./Blur";

export default {
    data () {
        return {
            show: false,
            accounts: [],
        };
    },
    components: {
        Blur,
    },
    methods: {
        open () {
            this.show = true;
        },

        close () {
            this.show = false;
        },


        async submit () {

            const response = await _api.post('customer/delete-profile', {}, {}, false);

            if (response.result === 'success') {
                // todo: notification, redirect to home?
                _auth.logout();
                this.close();

                this.$router.push({name: 'landing'});
            }
        },
    },
};
</script>
