import * as _api from './api';
import * as _log from './log';

// set the logger
const logger = _log.get('ADDRESS');

const retrieve = async (zipcode, housenumber) => {

    let data = {
        zipcode: zipcode,
        housenumber: housenumber,
    };

    logger.log('retrieve for', data)();

    let response = await _api.post('address', data);

    return response;
}

const isZipcodeAvailable = async (zipcode) => {

    if (!zipcode) {
        return false;
    }
    let data = {
        zipcode: zipcode,
    };

    logger.log('check availablitiy zipcode', data)();

    let response = await _api.post('address/zipcode-available', data);

    return response.available;
}


export {
    retrieve,
    isZipcodeAvailable,
}
